










































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { UtilsDate } from "@/utils/utils-date";
import { UtilsNumber } from "@/utils/utils-number";
import { CalendarDay } from "../Cards/CalendarDay";

@Component({
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
  },
})
export default class MaterialOnlyOneMouht extends Vue {
  @Prop() value!: CalendarDay[];
  @Prop({ default: 2 }) mouth!: number;
  @Prop({ default: 2020 }) year!: number;
  @Prop() color!: string;
  @Prop() seleccionar!: boolean;

  public days: CalendarDay[] = [];
  private IsClickedButton = false;
  private dropdwn: CalendarDay = new CalendarDay();

  created() {
    this.Generate_Days();
  }

  // Días de la semana sin uso de i18n
  get dias_semana() {
    return ["L", "M", "X", "J", "V", "S", "D"];
  }

  public get name_mouth() {
    return UtilsDate.NameMouth(this.mouth - 1);
  }
  get days_in_mouth() {
    return UtilsDate.daysinMouth(this.mouth, this.year);
  }

  get lis_demas() {
    return Math.max(0, this.day_in_week(1).dayIndex - 1);
  }

  @Watch("mouth")
  @Watch("year")
  ChangeMonthOrYear() {
    this.Generate_Days();
  }

  Generate_Days() {
    this.days = Array.from({ length: this.days_in_mouth }, (_, i) => {
      const dayNumber = i + 1;
      const existingDay =
        (this.value &&
          this.value.find(
            (x) =>
              x.Day === dayNumber &&
              x.Mouth === this.mouth &&
              x.Year === this.year
          )) ||
        null;

      return (
        existingDay ||
        new CalendarDay({
          Day: dayNumber,
          CssClass: "inactive noselect",
          activo: false,
          Color: "white",
          Mouth: this.mouth,
          Year: this.year,
          CompleteDate: new Date(
            `${this.year}-${UtilsNumber.formatNumberWhitZero(
              this.mouth
            )}-${UtilsNumber.formatNumberWhitZero(dayNumber)}`
          ),
          FormatDateStr: `${this.year}/${UtilsNumber.formatNumberWhitZero(
            this.mouth
          )}/${UtilsNumber.formatNumberWhitZero(dayNumber)}`,
          anomesdia: `${this.year}${UtilsNumber.formatNumberWhitZero(
            this.mouth
          )}${UtilsNumber.formatNumberWhitZero(dayNumber)}`,
          seleccionado: false,
        })
      );
    });
  }

  day_in_week(day: number) {
    return UtilsDate.dayOfWeek(this.mouth - 1, this.year, day);
  }

  dayDown(dia: CalendarDay) {
    this.dropdwn = dia;
  }

  dayHover(dia: CalendarDay) {
    if (this.IsClickedButton) {
      if (this.dropdwn.Day) {
        this.select_day(this.dropdwn);
        this.dropdwn = new CalendarDay();
      }
      this.select_day(dia);
    }
  }

  mousedown() {
    this.IsClickedButton = true;
  }

  mouseup() {
    this.IsClickedButton = false;
  }

  select_day(dia: CalendarDay) {
    if (!this.color && !this.seleccionar) return;

    const index = this.days.findIndex((x) => x.Day === dia.Day);
    if (index === -1) return;

    if (this.days[index].activo && this.days[index].Color === this.color) {
      this.days[index].Color = "";
      this.days[index].activo = false;
    } else if (this.seleccionar) {
      this.days[index].seleccionado = !this.days[index].seleccionado;
      this.days[index].liPadding = this.days[index].seleccionado ? 1 : 2;
    } else {
      this.days[index].Color = this.color;
      this.days[index].activo = true;
    }

    this.days[index].CssClass = this.getClasseDay(dia);

    this.$emit(
      "input",
      this.days.filter((x) => x.activo)
    );
    this.$emit(
      "selectedDays",
      this.days.filter((x) => x.seleccionado)
    );
    this.$emit(
      "selectedDay",this.days[index]
    );
  }

  getClasseDay(dia: CalendarDay) {
    if (!dia.activo) return "inactive noselect";
    return this.day_in_week(dia.Day).dayIndex >= 6
      ? "finde_active noselect"
      : "active noselect";
  }

  diaCssClass(dia: CalendarDay) {
    return dia.seleccionado
      ? `${dia.CssClass} font-italic font-weight-bold rcornerselectedday`
      : dia.CssClass;
  }

  diaStyles(dia: CalendarDay) {
    return {
      backgroundColor: dia.Color,
      padding: `${dia.liPadding}px`,
      fontSize: "12px",
    };
  }
}
